import styles from './Header.module.scss';
import {Link} from 'react-router-dom';

function Header() {
    return (
        <header>
            <Link to="/">
                <img className={styles.logo}
                     src={"/logogray.png"}
                     alt={"Rubio Porto Architects"}/>
            </Link>
        </header>
    );
}

export default Header;

import React from 'react';
import styles from './Contact.module.scss';
import {Link} from "react-router-dom";
function Contact() {
    return (
        <main>
            <h1>/ Contacto</h1>
            <div className={styles.container}>
                <div className={styles.pic}></div>
                <div className={styles.content}>
                    <div className={styles.map}>
                        <Link to={"https://www.google.com/maps/place/World+Trade+Center+Montevideo+Torre+2/@-34.9044117,-56.1382797,17z/data=!3m1!4b1!4m6!3m5!1s0x959f818152129fc7:0x381531717d40442!8m2!3d-34.9044117!4d-56.136091!16s%2Fg%2F11gw01g1b0"}>
                        </Link>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.address}>
                            <p><strong>World Trade Center</strong></p>
                            <p>Torre 2 Of. 811</p>
                            <p>Montevideo</p>
                            <p>T <Link to={"tel:+59826238067"}>+598 2623 8067</Link></p>
                        </div>
                        <div className={styles.partner}>
                            <span className={styles.name}>MDI/ARQ. Daniel Rubio Porto</span>
                            <span>T <Link to={"tel:+59899321537"} className={styles.tel}>+598 99 321 537</Link></span>
                            <span>T <Link to={"tel:+5491157340203"} className={styles.tel}>+54 911 57340203</Link></span>
                            <span><Link to={"mailto:daniel@rpamvd.com"}>daniel@rpamvd.com</Link></span>
                        </div>
                        <div className={styles.partner}>
                            <span className={styles.name}>ARQ. Clara Gossn</span>
                            <span>T <Link to={"tel:+59899270427"} className={styles.tel}>+598 99 270 427</Link></span>
                            <span>T <Link to={"tel:+5491157340203"} className={styles.tel}>+54 911 57340203</Link></span>
                            <span><Link to={"mailto:clara@rpamvd.com"}>clara@rpamvd.com</Link></span>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
export default Contact;

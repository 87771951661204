import React from 'react';
import {Link} from "react-router-dom";
import styles from './Obras.module.scss';

function Obras() {

    const elements = [
        {name: "Proyecto Urbano Buceo", image:"/projects/21-proyecto-urbano-buceo/main.jpg", link:"/project/proyectourbanobuceo/#"},
        {name: "Ciudad Parque", image:"/projects/22-ciudad-parque/main.jpg", link:"/project/ciudadparque/#"},
        {name: "La Salina", image:"/projects/01-la-salina/main.jpg", link:"/project/lasalina/#"},
        {name: "Roosevelt", image:"/projects/02-roosevelt/main.jpg", link:"/project/roosevelt/#"},
        {name: "Torres del Este", image:"/projects/37-chiverta/main.jpg", link:"/project/torresdeleste/#"},
        {name: "Sea Side II", image:"/projects/29-sea-side-II/main.jpg", link:"/project/seasideII/#"},
        {name: "Office 25", image:"/projects/03-office-25/main.jpg", link:"/project/office25/#"},
        {name: "Carrasco Parques", image:"/projects/07-carrasco-parques/main.jpg", link:"/project/carrascoparques/#"},
        {name: "Urban Port III", image:"/projects/05-urban-port-III/main.jpg", link:"/project/urbanportIII/#"},
        {name: "Urban Suites Punta Carretas II", image:"/projects/06-urban-suites-punta-carretas-II/main.jpg", link:"/project/urbansuitespuntacarretasII/#"},
        {name: "Carrasco Trade Park", image:"/projects/04-carrasco-trade-park/main.jpg", link:"/project/carrascotradepark/#"},
        {name: "Mansa Bay", image:"/projects/08-mansa-bay/main.jpg", link:"/project/mansabay/#"},
        {name: "Urban Suites Punta Carretas I", image:"/projects/09-urban-suites-punta-carretas-I/main.jpg", link:"/project/urbansuitespuntacarretasI/#"},
        {name: "Urban Suites I", image:"/projects/10-urban-suites-I/main.jpg", link:"/project/urbansuitesI/#"},
        {name: "Urban Suites II", image:"/projects/11-urban-suites-II/main.jpg", link:"/project/urbansuitesII/#"},
        {name: "Urban Suites III", image:"/projects/12-urban-suites-III/main.jpg", link:"/project/urbansuitesIII/#"},
        {name: "Urban Suites IV", image:"/projects/13-urban-suites-IV/main.jpg", link:"/project/urbansuitesIV/#"},
        {name: "Urban Suites V", image:"/projects/14-urban-suites-V/main.jpg", link:"/project/urbansuitesV/#"},
        {name: "Urban Suites VI", image:"/projects/15-urban-suites-VI/main.jpg", link:"/project/urbansuitesVI/#"},
        {name: "Urban Suites VII", image:"/projects/16-urban-suites-VII/main.jpg", link:"/project/urbansuitesVII/#"},
        {name: "Novus Lamas", image:"/projects/17-novus-lamas/main.jpg", link:"/project/novuslamas/#"},
        {name: "Pocitos Inn", image:"/projects/18-pocitos-inn/main.jpg", link:"/project/pocitosinn/#"},
        {name: "Urban Suites VIII", image:"/projects/19-urban-suites-VIII/main.jpg", link:"/project/urbansuitesVIII/#"},
        {name: "Urban Suites IX", image:"/projects/20-urban-suites-IX/main.jpg", link:"/project/urbansuitesIX/#"},
        {name: "Urban Suites Punta Carretas III", image:"/projects/23-urban-suites-punta-carretas-III/main.jpg", link:"/project/urbansuitespuntacarretasIII/#"},
        {name: "Urban Port I", image:"/projects/24-urban-port-I/main.jpg", link:"/project/urbanportI/#"},
        {name: "Urban Port II", image:"/projects/25-urban-port-II/main.jpg", link:"/project/urbanportII/#"},
        {name: "Urban Suites Malvin", image:"/projects/26-urban-suites-malvin/main.jpg", link:"/project/urbansuitesmalvin/#"},
        {name: "Carrasco Inn", image:"/projects/27-carrasco-inn/main.jpg", link:"/project/carrascoinn/#"},
        {name: "Sea Side I", image:"/projects/28-sea-side-I/main.jpg", link:"/project/seasideI/#"},
        {name: "Sea Side III", image:"/projects/30-sea-side-III/main.jpg", link:"/project/seasideIII/#"},
        {name: "La Candelaria", image:"/projects/31-la-candelaria/main.jpg", link:"/project/lacandelaria/#"},
        {name: "Mansa Inn I", image:"/projects/32-mansa-inn-I/main.jpg", link:"/project/mansainnI/#"},
        {name: "Mansa Inn II", image:"/projects/33-mansa-inn-II/main.jpg", link:"/project/mansainnII/#"},
        {name: "Mansa Inn Beach", image:"/projects/34-mansa-inn-beach/main.jpg", link:"/project/mansainnbeach/#"},
        {name: "Mansa Inn III", image:"/projects/35-mansa-inn-III/main.jpg", link:"/project/mansainnIII/#"},
        {name: "Mansa Wave", image:"/projects/36-mansa-wave/main.jpg", link:"/project/mansawave/#"},
        {name: "Av. Italia", image:"/projects/38-avitalia/main.jpg", link:"/project/avitalia/#"},
        {name: "Park Avenue", image:"/projects/39-park-avenue/main.jpg", link:"/project/parkavenue/#"},
        {name: "Sea Side IV", image:"/projects/40-sea-side-IV/main.jpg", link:"/project/seasideIV/#"},
        // name: "Plaza de la bandera", image:"/projects/41-plaza-bandera/main.jpg", link:"/project/plazadelabandera/#/",
        {name: "Saint Honore", image:"/projects/42-saint-honore/main.jpg", link:"/project/sainthonore/#"},
        {name: "Torres Roosevelt", image:"/projects/43-torres-roosevelt/main.jpg", link:"/project/torresroosevelt/#"},
        {name: "Centro Cultural El Bolsón", image:"/projects/44-cc-bolson/main.jpg", link:"/project/centroculturalbolson/#"},
        {name: "Sede Gub. Buenos Aires", image:"/projects/45-sede-gub-bsas/main.jpg", link:"/project/sedegubbsas/#"},
        {name: "Masterplan Riachuelo", image:"/projects/46-masterplan-riachuelo/main.jpg", link:"/project/masterplanriachuelo/#"},
        {name: "Av. Libertador", image:"/projects/47-av-libertador/main.jpg", link:"/project/avlibertador/#"},
        {name: "Roosevelt y Chiossi", image:"/projects/48-roosevelt-chiossi/main.jpg", link:"/project/rooseveltchiossi/#"},
        {name: "Sudeste", image:"/projects/49-sudeste/main.jpg", link:"/project/sudeste/#"},
    ]

    return (
        <main>
            <h1>/ Proyectos y Obras</h1>
            <div className={styles.container}>
                <div className={styles.listItems}>
                    {
                        elements.map(
                            key => (
                                <Link to={key.link} className={styles.project}>
                                    <div className={styles.img} style={{ backgroundImage: `url(${key.image})` }}>&nbsp;</div>
                                    <p className={styles.projectName}>{key.name}</p>
                                </Link>
                            )
                        )
                    }
                </div>
            </div>
        </main>
    );
}
export default Obras;

import React from 'react';
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import styles from './Project.module.scss';

function Project() {

    const {id} = useParams();

    const elements = {
        lasalina: {
            name: "La Salina",
            address: "Peninsula - Virazon y La Salina",
            type: "Residencial",
            city: "Punta del Este",
            year: "2019",
            m2: "3500",
            status: "Construido",
            mainImage:["/projects/01-la-salina/main.jpg"],
            images:["/projects/01-la-salina/02.jpg", "/projects/01-la-salina/03.jpg"]},
        roosevelt: {
            name: "Roosevelt",
            address: "Peninsula - Virazon y La Salina",
            type: "Mixto",
            city: "Punta del Este",
            year: "2022",
            m2: "2900",
            status: "Proyecto",
            mainImage:["/projects/02-roosevelt/main.jpg"],
            images:["/projects/02-roosevelt/02.jpg"]},
        office25: {
            name: "Office 25",
            address: "25 de Mayo 740",
            type: "Comercial",
            city: "Montevideo",
            year: "2018",
            m2: "5000",
            status: "Proyecto",
            mainImage:["/projects/03-office-25/main.jpg"],
            images:[""]},
        oficinascarrasco: {
            name: "Carrasco Parques",
            address: "Camino Carrasco esq. Brunel",
            type: "Comercial",
            city: "Montevideo",
            year: "2022",
            m2: "6350",
            status: "Proyecto",
            mainImage:["/projects/07-carrasco-parques/main.jpg"],
            images:[""]},
        urbanportIII: {
            name: "Urban Port III",
            address: "Puertito del Buceo - Av. Gral. Rivera 3665",
            type: "Residencial",
            city: "Montevideo",
            year: "2022",
            m2: "2200",
            status: "En Construcción",
            mainImage:["/projects/05-urban-port-III/main.jpg"],
            images:["/projects/05-urban-port-III/02.jpg"]},
        urbansuitespuntacarretasII: {
            name: "Urban Suites Punta Carretas II",
            address: "Punta Carretas - Av. Sarmiento 2548",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "1240",
            status: "En Construcción",
            mainImage:["/projects/06-urban-suites-punta-carretas-II/main.jpg"],
            images:["/projects/06-urban-suites-punta-carretas-II/02.jpg"]},
        carrascotradepark: {
            name: "Carrasco Trade Park",
            address: "Camino Carrasco esq. Brunel",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "6350",
            status: "En Construcción",
            mainImage:["/projects/04-carrasco-trade-park/main.jpg"],
            images:["/projects/04-carrasco-trade-park/02.jpg", "/projects/04-carrasco-trade-park/03.jpg", "/projects/04-carrasco-trade-park/04.jpg"]},
        mansabay: {
            name: "Mansa Bay",
            address: "Mansa - Parada 33",
            type: "Residencial",
            city: "Punta del Este",
            year: "2021",
            m2: "3000",
            status: "Construido",
            mainImage:["/projects/08-mansa-bay/main.jpg"],
            images:["/projects/08-mansa-bay/02.jpg", "/projects/08-mansa-bay/03.jpg"]},
        urbansuitespuntacarretasI: {
            name: "Urban Suites Punta Carretas I",
            address: "Punta Carretas - Ellauri 494",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "1840",
            status: "En Construcción",
            mainImage:["/projects/09-urban-suites-punta-carretas-I/main.jpg"],
            images:["/projects/09-urban-suites-punta-carretas-I/02.jpg"]},
        urbansuitesI: {
            name: "Urban Suites I",
            address: "WTC - Plácido Ellauri 3418",
            type: "Residencial",
            city: "Montevideo",
            year: "2018",
            m2: "1500",
            status: "Construido",
            mainImage:["/projects/10-urban-suites-I/main.jpg"],
            images:[]},
        urbansuitesII: {
            name: "Urban Suites II",
            address: "WTC - Plácido Ellauri 3418",
            type: "Residencial",
            city: "Montevideo",
            year: "2019",
            m2: "2000",
            status: "Construido",
            mainImage:["/projects/11-urban-suites-II/main.jpg"],
            images:[]},
        urbansuitesIII: {
            name: "Urban Suites III",
            address: "WTC - Pereira de la Luz 1331",
            type: "Residencial",
            city: "Montevideo",
            year: "2019",
            m2: "1600",
            status: "Construido",
            mainImage:["/projects/12-urban-suites-III/main.jpg"],
            images:[]},
        urbansuitesIV: {
            name: "Urban Suites IV",
            address: "WTC - Luis Lamas 3383",
            type: "Residencial",
            city: "Montevideo",
            year: "2019",
            m2: "1700",
            status: "Construido",
            mainImage:["/projects/13-urban-suites-IV/main.jpg"],
            images:[]},
        urbansuitesV: {
            name: "Urban Suites V",
            address: "WTC - Luis Lamas 3386",
            type: "Residencial",
            city: "Montevideo",
            year: "2019",
            m2: "1900",
            status: "Construido",
            mainImage:["/projects/14-urban-suites-V/main.jpg"],
            images:[]},
        urbansuitesVI: {
            name: "Urban Suites VI",
            address: "WTC - Laguna 3316",
            type: "Residencial",
            city: "Montevideo",
            year: "2020",
            m2: "1600",
            status: "Construido",
            mainImage:["/projects/15-urban-suites-VI/main.jpg"],
            images:[]},
        urbansuitesVII: {
            name: "Urban Suites VII",
            address: "WTC - Luis Lamas 3374",
            type: "Residencial",
            city: "Montevideo",
            year: "2020",
            m2: "1500",
            status: "Construido",
            mainImage:["/projects/16-urban-suites-VII/main.jpg"],
            images:[]},
        novuslamas: {
            name: "Novus Lamas",
            address: "WTC - Luis Lamas 3285",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "3000",
            status: "En Construcción",
            mainImage:["/projects/17-novus-lamas/main.jpg"],
            images:["/projects/17-novus-lamas/02.jpg"]},
        pocitosinn: {
            name: "Pocitos Inn",
            address: "Gabriel Pereira 2966",
            type: "Residencial",
            city: "Montevideo",
            year: "2022",
            m2: "2800",
            status: "En Construcción",
            mainImage:["/projects/18-pocitos-inn/main.jpg"],
            images:[]},
        urbansuitesVIII: {
            name: "Urban Suites VIII",
            address: "WTC - Luis Lamas 3370",
            type: "Residencial",
            city: "Montevideo",
            year: "2020",
            m2: "1500",
            status: "Construido",
            mainImage:["/projects/19-urban-suites-VIII/main.jpg"],
            images:["/projects/19-urban-suites-VIII/02.jpg"]},
        urbansuitesIX: {
            name: "Urban Suites IX",
            address: "WTC - Luis Lamas 3350",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "1800",
            status: "En Construcción",
            mainImage:["/projects/20-urban-suites-IX/main.jpg"],
            images:[]},
        proyectourbanobuceo: {
            name: "Proyecto Urbano Buceo",
            address: "Rambla República de Chile",
            type: "Mixto",
            city: "Montevideo",
            year: "2012",
            m2: "200000",
            status: "En Construcción",
            mainImage:["/projects/21-proyecto-urbano-buceo/main.jpg"],
            images:[]},
        ciudadparque: {
            name: "Ciudad Parque",
            address: "Cerro Pelado",
            type: "Mixto",
            city: "Maldonado",
            year: "2015",
            m2: "100000",
            status: "Proyecto",
            mainImage:["/projects/22-ciudad-parque/main.jpg"],
            images:["/projects/22-ciudad-parque/02.jpg", "/projects/22-ciudad-parque/03.jpg"]},
        urbansuitespuntacarretasIII: {
            name: "Urban Suites Punta Carretas III",
            address: "Punta Carretas - Solano Antuña",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "1800",
            status: "En Construcción",
            mainImage:["/projects/23-urban-suites-punta-carretas-III/main.jpg"],
            images:[]},
        urbanportI: {
            name: "Urban Port I",
            address: "Puertito del Buceo - Bustamante 1194",
            type: "Residencial",
            city: "Montevideo",
            year: "2019",
            m2: "3000",
            status: "Construido",
            mainImage:["/projects/24-urban-port-I/main.jpg"],
            images:[]},
        urbanportII: {
            name: "Urban Port II",
            address: "Puertito del Buceo - Miguel Grau 3820",
            type: "Residencial",
            city: "Montevideo",
            year: "2020",
            m2: "3000",
            status: "Construido",
            mainImage:["/projects/25-urban-port-II/main.jpg"],
            images:[]},
        urbansuitesmalvin: {
            name: "Urban Suites Malvin",
            address: "Amazonas 1430",
            type: "Residencial",
            city: "Montevideo",
            year: "2022",
            m2: "2200",
            status: "En Construcción",
            mainImage:["/projects/26-urban-suites-malvin/main.jpg"],
            images:["/projects/26-urban-suites-malvin/02.jpg"]},
        carrascoinn: {
            name: "Carrasco Inn",
            address: "Av. Italia 7207",
            type: "Residencial",
            city: "Montevideo",
            year: "2022",
            m2: "2200",
            status: "En Construcción",
            mainImage:["/projects/27-carrasco-inn/main.jpg"],
            images:["/projects/27-carrasco-inn/02.jpg", "/projects/27-carrasco-inn/03.jpg"]},
        seasideI: {
            name: "Sea Side I",
            address: "Pocitos - Echevarriarza 3398",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "2700",
            status: "En Construcción",
            mainImage:["/projects/28-sea-side-I/main.jpg"],
            images:[]},
        seasideII: {
            name: "Sea Side II",
            address: "Pocitos - Echevarriarza y Pereira de la Luz",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "8200",
            status: "En Construcción",
            mainImage:["/projects/29-sea-side-II/main.jpg"],
            images:["/projects/29-sea-side-II/02.jpg", "/projects/29-sea-side-II/03.jpg"]},
        seasideIII: {
            name: "Sea Side III",
            address: "Camino de los Hormigueros 3209",
            type: "Residencial",
            city: "Montevideo",
            year: "2022",
            m2: "3500",
            status: "En Construcción",
            mainImage:["/projects/30-sea-side-III/main.jpg"],
            images:["/projects/30-sea-side-III/02.jpg", "/projects/30-sea-side-III/03.jpg"]},
        lacandelaria: {
            name: "La Candelaria",
            address: "Peninsula - 2 de Febrero y La Salina",
            type: "Residencial",
            city: "Punta del Este",
            year: "2019",
            m2: "3500",
            status: "Proyecto",
            mainImage:["/projects/31-la-candelaria/main.jpg"],
            images:["/projects/31-la-candelaria/02.jpg"]},
        mansainnI: {
            name: "Mansa Inn I",
            address: "Mansa - Av. Figueroa y Hermida",
            type: "Residencial",
            city: "Punta del Este",
            year: "2019",
            m2: "3800",
            status: "Construido",
            mainImage:["/projects/32-mansa-inn-I/main.jpg"],
            images:["/projects/32-mansa-inn-I/02.jpg", "/projects/32-mansa-inn-I/03.jpg"]},
        mansainnII: {
            name: "Mansa Inn II",
            address: "Mansa - Av. Figueroa y Hermida",
            type: "Residencial",
            city: "Punta del Este",
            year: "2019",
            m2: "4000",
            status: "Construido",
            mainImage:["/projects/33-mansa-inn-II/main.jpg"],
            images:["/projects/33-mansa-inn-II/02.jpg"]},
        mansainnbeach: {
            name: "Mansa Inn Beach",
            address: "Mansa - Rambla Parada 11",
            type: "Residencial",
            city: "Punta del Este",
            year: "2021",
            m2: "4300",
            status: "En Construcción",
            mainImage:["/projects/34-mansa-inn-beach/main.jpg"],
            images:[]},
        mansainnIII: {
            name: "Mansa Inn III",
            address: "Mansa - Av. Figueroa y Pascual",
            type: "Residencial",
            city: "Punta del Este",
            year: "2021",
            m2: "3000",
            status: "Construido",
            mainImage:["/projects/35-mansa-inn-III/main.jpg"],
            images:["/projects/35-mansa-inn-III/02.jpg"]},
        mansawave: {
            name: "Mansa Wave",
            address: "Mansa - Parada 27",
            type: "Residencial",
            city: "Punta del Este",
            year: "2021",
            m2: "3100",
            status: "Construido",
            mainImage:["/projects/36-mansa-wave/main.jpg"],
            images:["/projects/36-mansa-wave/02.jpg", "/projects/36-mansa-wave/03.jpg"]},
        torresdeleste: {
            name: "Torres del Este",
            address: "Av. Chiverta",
            type: "Residencial",
            city: "Punta del Este",
            year: "2023",
            m2: "55000",
            status: "En Construcción",
            mainImage:["/projects/37-chiverta/main.jpg"],
            images:["/projects/37-chiverta/02.jpg", "/projects/37-chiverta/03.jpg", "/projects/37-chiverta/04.jpg", "/projects/37-chiverta/05.jpg"]},
        avitalia: {
            name: "Av. Italia",
            address: "Av. Italia esq. Comercio",
            type: "Residencial",
            city: "Montevideo",
            year: "2017",
            m2: "9500",
            status: "Proyecto",
            mainImage:["/projects/38-avitalia/main.jpg"],
            images:[]},
        parkavenue: {
            name: "Park Avenue",
            address: "Av. Italia esq. Albo",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "9500",
            status: "Proyecto",
            mainImage:["/projects/39-park-avenue/main.jpg"],
            images:[]},
        seasideIV: {
            name: "Sea Side IV",
            address: "Camino de los Hormigueros 3212",
            type: "Residencial",
            city: "Montevideo",
            year: "2021",
            m2: "2450",
            status: "Proyecto",
            mainImage:["/projects/40-sea-side-IV/main.jpg"],
            images:[]},
        plazadelabandera: {
            name: "Plaza de la bandera",
            address: "Explanada de Tres Cruces",
            type: "Mixto",
            city: "Montevideo",
            year: "2011",
            m2: "23084",
            status: "Proyecto",
            mainImage:["/projects/41-plaza-banderas/main.jpg"],
            images:[]},
        sainthonore: {
            name: "Saint Honore",
            address: "Mansa - Parada 4",
            type: "Residencial",
            city: "Punta del Este",
            year: "2017",
            m2: "9500",
            status: "Construido",
            mainImage:["/projects/42-saint-honore/main.jpg"],
            images:[]},
        torresroosevelt: {
            name: "Torres Roosevelt",
            address: "Roosevelt y Camacho",
            type: "Mixto",
            city: "Maldonado",
            year: "2022",
            m2: "3500",
            status: "Proyecto",
            mainImage:["/projects/43-torres-roosevelt/main.jpg"],
            images:["/projects/43-torres-roosevelt/02.jpg"]},
        centroculturalbolson: {
            name: "Centro Cultural El Bolsón",
            address: "Rio Negro - Argentina",
            type: "Institucional",
            city: "El Bolsón",
            year: "2014",
            m2: "2300",
            status: "Concurso",
            mainImage:["/projects/44-cc-bolson/main.jpg"],
            images:["/projects/44-cc-bolson/02.jpg"]},
        sedegubbsas: {
            name: "Sede Gubernamental Buenos Aires",
            address: "Buenos Aires - Argentina",
            type: "Institucional",
            city: "Buenos Aires",
            year: "2012",
            m2: "125000",
            status: "Concurso",
            mainImage:["/projects/45-sede-gub-bsas/main.jpg"],
            images:["/projects/45-sede-gub-bsas/02.jpg", "/projects/45-sede-gub-bsas/03.jpg", "/projects/45-sede-gub-bsas/04.jpg"]},
        masterplanriachuelo: {
            name: "Masterplan Riachuelo",
            address: "Buenos Aires - Argentina",
            type: "Mixto",
            city: "Buenos Aires",
            year: "2014",
            m2: "1200000",
            status: "Proyecto",
            mainImage:["/projects/46-masterplan-riachuelo/main.jpg"],
            images:[]},
        avlibertador: {
            name: "Av. Libertador",
            address: "Av. Libertador esq. Mercedes",
            type: "Mixto",
            city: "Montevideo",
            year: "2022",
            m2: "30500",
            status: "Proyecto",
            mainImage:["/projects/47-av-libertador/main.jpg"],
            images:[]},
        rooseveltchiossi: {
            name: "Roosevelt y Chiossi",
            address: "Av. Roosevelt y Chiossi",
            type: "Residencial",
            city: "Punta del Este",
            year: "2023",
            m2: "8500",
            status: "En Construcción",
            mainImage:["/projects/48-roosevelt-chiossi/main.jpg"],
            images:["/projects/48-roosevelt-chiossi/02.jpg", "/projects/48-roosevelt-chiossi/03.jpg", "/projects/48-roosevelt-chiossi/04.jpg", "/projects/48-roosevelt-chiossi/05.jpg"]},
        sudeste: {
            name: "Sudeste",
            address: "Peninsula, Calle 24 y 21",
            type: "Residencial",
            city: "Punta del Este",
            year: "2023",
            m2: "4100",
            status: "En Construcción",
            mainImage:["/projects/49-sudeste/main.jpg"],
            images:["/projects/49-sudeste/02.jpg", "/projects/49-sudeste/03.jpg", "/projects/49-sudeste/04.jpg"]},
    }

    return (
        <main>
            <div className={styles.container}>
                <Link to={"/proyectos-obras"} className={styles.breadcrumbs}><img src={"/icon/chevronLeft.svg"} alt={""} /> / <span>Obras</span></Link>
                <div className={styles.content}>
                    <div className={styles.infoPic}>
                        {elements[id]?.mainImage.map(
                            key => (
                        <div className={styles.pic} style={{ backgroundImage: `url(${key})` }}></div>
                            )
                        )}
                        <div className={styles.info}>
                            <div className={styles.name}>
                                {elements[id]?.name}
                            </div>
                            <div className={styles.lines}>
                                <div className={styles.line}>{elements[id]?.year}<br />{elements[id]?.type} | {elements[id]?.m2} M2</div>
                                <div className={styles.line}>{elements[id]?.city}<br />{elements[id]?.address}</div>
                                <div className={styles.line}>{elements[id]?.status}</div>
                            </div>
                        </div>
                        <div className={styles.gallery}>
                            {elements[id]?.images.map(
                                key => (
                                    <div className={styles.img} style={{ backgroundImage: `url(${key})` }}>&nbsp;</div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Project;

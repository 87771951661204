import React from 'react';
import styles from './Home.module.scss';
import Slider from "react-slick";

function Home() {
    const settings = {
        arrows:false,
        autoplay:true,
        autoplaySpeed: 2000,
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (

        <main className={styles.bg}>
            { /* <h1>/ Home</h1> */ }
            <Slider {...settings} className={styles.slider}>
                <div className={styles.slide}>
                    <div className={styles.content}>
                        <div className={styles.foto}>
                            <img src={"/img/01.jpg"} alt={""} />
                        </div>
                        <div className={styles.info}>
                            <p><strong>1.</strong><br />Somos una firma profesional dedicada a la arquitectura de proyectos de inversión en real estate.</p>
                        </div>
                    </div>
                </div>
                <div className={styles.slide}>
                    <div className={styles.content}>
                        <div className={styles.info}>
                            <p><strong>2.</strong><br />Este enfoque de alta especialización nos permite comprender y dominar de manera absoluta e integral, todas las variables del desarrollo inmobiliario, logrando así proyectos de máxima calidad e inversiones con performances superiores.</p>
                        </div>
                        <div className={styles.foto}>
                            <img src={"/img/02.jpg"} alt={""} />
                        </div>
                    </div>
                </div>
                <div className={styles.slide}>
                    <div className={styles.content}>
                        <div className={styles.foto}>
                            <img src={"/img/03.jpg"} alt={""} />
                        </div>
                        <div className={styles.info}>
                            <p><strong>3.</strong><br />Nuestra organización está integrada por profesionales de amplia experiencia y reconocida trayectoria, con especializaciones académicas en diversas universidades y países, que nos otorgan amplia solvencia técnica y gran riqueza creativa.</p>
                        </div>
                    </div>
                </div>
                <div className={styles.slide}>
                    <div className={styles.content}>
                        <div className={styles.info}>
                            <p><strong>4.</strong><br />Desarrollamos esta actividad de acuerdo a una visión propia, basada en la profesionalidad, la innovación permanente, la creatividad y la calidad de todos los procesos y resultados.</p>
                        </div>
                        <div className={styles.foto}>
                            <img src={"/img/04.jpg"} alt={""} />
                        </div>
                    </div>
                </div>
                <div className={styles.slide}>
                    <div className={styles.content}>
                        <div className={styles.foto}>
                            <img src={"/img/05.jpg"} alt={""} />
                        </div>
                        <div className={styles.info}>
                            <p><strong>5.</strong><br />Cada proyecto es un renovado desafío a nuestras habilidades y un enriquecimiento continuo de nuestras capacidades, a partir de la cuales construimos nuestra singular identidad profesional.</p>
                        </div>
                    </div>
                </div>
            </Slider>
        </main>
    );
}
export default Home;

import styles from './Menu.module.scss';
import {Link} from 'react-router-dom';

function Menu({show, handler}) {
    const {showMenu} = show;
    const {handleBurger} = handler;

    return (
        <nav id="menu" className={showMenu ? styles.active : ''}>
            <Link onClick={handleBurger} to="/">Home</Link>
            <Link onClick={handleBurger} to="/proyectos-obras">Proyectos y Obras</Link>
            <Link onClick={handleBurger} to="/equipo">Equipo</Link>
            <Link onClick={handleBurger} to="/contacto">Contacto</Link>
        </nav>
    );
}

export default Menu;

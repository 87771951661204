import React from "react";
import './HamburgerMenu.scss';

function HamburgerMenu({handler, show}) {
    const {showMenu} = show;
    const {handleBurger} = handler;

    return (
        <button className={showMenu ? "hamburger hamburger--slider is-active" : "hamburger hamburger--slider non-active"} id="hamburgerBtn" type="button" onClick={handleBurger}>
            <span className="hamburger-box">
                <span className="hamburger-inner"></span>
            </span>
        </button>
    );
}

export default HamburgerMenu;

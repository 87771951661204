import React from 'react';
import {Link} from "react-router-dom";
import styles from "../Obras/Obras.module.scss";

function Page404() {

    return (
        <main>
            <h1>404</h1>
            <div className={styles.container}>
                <div>La página que estás buscando no existe.</div>
                <br />
                <Link to="/">Volver a Home</Link>.
            </div>
        </main>
    );
}

export default Page404;

import React from 'react';
import styles from "./Team.module.scss";
function Team() {
    return (
        <main>
            <h1>/ Equipo</h1>
            <div className={styles.container}>
                <div className={styles.pic}></div>
                <div className={styles.team}>
                    {/* Partners */}
                    <div className={styles.partners}>
                        {/* Daniel Rubio */}
                        <div className={styles.partner}>
                            <div className={styles.pic}>&nbsp;</div>
                            <div className={styles.name}>MDI/ARQ. Daniel Rubio</div>
                            <div className={styles.info}>
                                <p>Arquitecto UdelaR</p>
                                <p>MBA UCU</p>
                                <p>Master en Proyectos de Inversión Inmobiliaria por la Escuela Superior de Arquitectura del Instituto Politécnico de Madrid</p>
                            </div>
                        </div>
                        {/* Clara Gossn */}
                        <div className={styles.partner}>
                            <div className={styles.pic}>&nbsp;</div>
                            <div className={styles.name}>ARQ. Clara Gossn Laguzzi</div>
                            <div className={styles.info}>
                                <p>Arquitecta Universidad de Buenos Aires</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.divider}>&nbsp;</div>
                    {/* Resto del Staff */}
                    <div className={styles.staff}>
                        {/* Romina Marchand */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Romina Marchand</div>
                            <div className={styles.info}>
                                <p>Arquitecta Universidad Interamericana de Panamá</p>
                                <p>Master en BIM Manager por la Escuela CICE Madrid</p>
                            </div>
                        </div>
                        {/* Inés Mir */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Inés Mir</div>
                            <div className={styles.info}>
                                <p>Arquitecta UdelaR</p>
                            </div>
                        </div>
                        {/* Verónica Garcia */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Verónica Garcia</div>
                            <div className={styles.info}>
                                <p>Arquitecta UdelaR</p>
                                <p>Master en gestión de la edificación de la Politécnica de Valencia</p>
                                <p>Diploma de negocios inmobiliarios de la ORT</p>
                                <p>CAPM de PMI</p>
                            </div>
                        </div>
                        {/* Julia Martinez */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Julia Martinez</div>
                            <div className={styles.info}>
                                <p>Arquitecta ORT</p>
                            </div>
                        </div>
                        {/* Susana Moumdjian */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Susana Moumdjian</div>
                            <div className={styles.info}>
                                <p>Arquitecta ORT</p>
                                <p>MBA IEEM</p>
                            </div>
                        </div>
                        {/* Carolina Nicivoscia */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Carolina Nicivoscia</div>
                            <div className={styles.info}>
                                <p>Arquitecta UdelaR</p>
                            </div>
                        </div>
                        {/* Marina Piñeyro Rodriguez */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Marina Piñeyro Rodriguez</div>
                            <div className={styles.info}>
                                <p>Arquitecta UdelaR</p>
                                <p>Master en Arquitectura UdelaR. Tesis en curso.</p>
                            </div>
                        </div>
                        {/* Victoria Straneo */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Victoria Straneo</div>
                            <div className={styles.info}>
                                <p>Arquitecta UdelaR</p>
                                <p>Master en Project Management Escuela de Negocios de Barcelona. En curso.</p>
                            </div>
                        </div>
                        {/* Mercedes Pirotto */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Mercedes Pirotto</div>
                            <div className={styles.info}>
                                <p>Arquitecta UdelaR</p>
                            </div>
                        </div>
                        {/* Paulina Bertinat */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Paulina Bertinat</div>
                            <div className={styles.info}>
                                <p>Arquitecta UdelaR</p>
                                <p>Posgrado en Diseño de Mobiliario. En curso.</p>
                            </div>
                        </div>
                        {/* Noelia de León */}
                        <div className={styles.person}>
                            <div className={styles.name}>Arq. Noelia de León</div>
                            <div className={styles.info}>
                                <p>Arquitecta UdelaR</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
export default Team;

import './App.scss';
import React, { useState } from "react";
import Header from "./Components/Header/Header";
import HamburgerMenu from './Components/HamburgerMenu/HamburgerMenu';
import Menu from './Components/Menu/Menu';
// Pages & Routing
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Obras from "./Pages/Obras/Obras";
import Team from "./Pages/Team/Team";
import Contact from "./Pages/Contact/Contact";
import Project from "./Pages/Project/Project";
import Page404 from "./Pages/Page404/Page404";

function App() {

    const [showMenu, setShowMenu] = useState(false);
    const handleBurger = function() {
        setShowMenu(!showMenu);
    }
    const childrenHandler = {handleBurger};
    const childrenProps = {showMenu};

    return (
      <>
          <Header />
          <HamburgerMenu show={childrenProps} handler={childrenHandler} />
          <Menu show={childrenProps} handler={childrenHandler} />
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/proyectos-obras" element={<Obras />} />
              <Route exact path="/equipo" element={<Team />} />
              <Route exact path="/contacto" element={<Contact />} />
              <Route path="/project/:id" element={<Project />} />
              <Route path="*" element={<Page404 />} />
          </Routes>
      </>
  );
}
export default App;
